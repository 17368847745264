
/////////////////////
// About Page
////////////////////

.quoteSection {
  position        : relative;
  background-color: $accent-color;
}

.quoteSectionWrapper {
  padding          : 6em 0;
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -ms-flex-pack    : distribute;
  justify-content  : space-around;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.quoteIntro {
  text-align: center;
  max-width : 75%;
}

.quoteTitle {
  text-transform: uppercase;
  color         : $white;
  margin        : 2em 0 0;
  position      : relative;
  padding       : 10px 0;
  display       : inline-block;
}

.quoteTitle:after {
  content : '';
  position: absolute;
  border  : $white 1px solid;
  width   : 50%;
  left    : 25%;
  top     : 0;
}

.quoteMain {
  padding      : 0;
  color        : $white;
  font-weight  : 600;
  font-size    : 2em;
  margin-bottom: .5em;
}

//

/* Slick Color Override*/
.slick-next:before,
.slick-prev:before {
  color: $brand-primary;
}

.slick-dots li {
  position: relative;
  display : inline-block;
  width   : 10px;
  height  : 10px;
  margin  : 0 7px;
  padding : 0;
  cursor  : pointer;
}

.slick-dots li button {
  font-size    : 0;
  line-height  : 0;
  display      : block;
  width        : 10px;
  height       : 10px;
  padding      : 5px;
  border-radius: 50%;
  cursor       : pointer;
  color        : rgba(0, 0, 0,.5);
  border       : 0;
  outline      : none;
  background   : rgba(0, 0, 0,.5);
}

.slick-dots li.slick-active button {
  background: rgba(0, 0, 0, .8);
}

.slick-dots li button:before {
  display: none;
}

/* Services Section - Homepage */

/* About Section - Homepage */
.aboutSection {
  position        : relative;
  background-color: #f4f4f4;
}

.aboutImage {}

.aboutIntro {
  width             : 40%;
  margin-left       : 60%;
  /* Animation */
  -webkit-transform : translateX(2em);
  transform         : translateX(2em);
  opacity           : 1;
  -webkit-transition: all ease .5s;
  transition        : all ease .5s;

}

.loaded .load .aboutIntro {
  -webkit-transform : translateX(0em);
  transform         : translateX(0em);
  opacity           : 1;
  -webkit-transition: all ease .5s;
  transition        : all ease .5s;
}

.aboutTitle {
  text-transform: uppercase;
  color         : $dark-grey;
  /*margin: 1em 0;*/
  position      : relative;
  padding       : 10px 0;
  display       : inline-block;
}

.aboutTitle:after {
  content : '';
  position: absolute;
  border  : $dark-grey 1px solid;
  width   : 50%;
  left    : 0;
  top     : 90%;
}

.aboutMain {
  padding      : 0;
  color        : $brand-primary;
  font-weight  : 600;
  font-size    : 3em;
  margin-bottom: .5em;
}

.aboutSubTitle {
  padding       : 0;
  text-transform: uppercase;
  font-size     : 17px;
  font-weight   : 100;
}

/* Work Section Homepage */
.workSection {
  background-color: #f4f4f4;
}

.featuredWork {
  display              : -webkit-box;
  display              : -ms-flexbox;
  display              : flex;
  -webkit-box-pack     : center;
  -ms-flex-pack        : center;
  justify-content      : center;
  -webkit-box-align    : center;
  -ms-flex-align       : center;
  align-items          : center;
  -webkit-box-orient   : vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction   : column;
  flex-direction       : column;
  padding              : 4rem;
}

.featuredWorkCallout {
  -ms-flex-preferred-size: 75%;
  flex-basis             : 75%;
  font-size              : 2.5em;
  font-weight            : 600;
  display                : -webkit-box;
  display                : -ms-flexbox;
  display                : flex;
  -webkit-box-pack       : center;
  -ms-flex-pack          : center;
  justify-content        : center;
  -webkit-box-align      : center;
  -ms-flex-align         : center;
  align-items            : center;
  padding-top            : 40px;
}

.featuredWorkCallout span {
  color: $brand-primary;
}

.featuredWorkTitle {
  text-transform: uppercase;
  color         : $dark-grey;
  margin        : 0;
  position      : relative;
  padding       : 10px;
}

.featuredWorkTitle:after {
  content : '';
  position: absolute;
  border  : $dark-grey 1px solid;
  width   : 50%;
  left    : 25%;
  top     : 90%;
}

figcaption.workOverlay {
  position: absolute;
  width   : 50%;
  top     : 4em;
  left    : 2em;
}

figcaption.workOverlay h2 {
  color        : $white;
  font-size    : 2.5em;
  font-weight  : 600;
  margin-bottom: 10px;
}

figcaption.workOverlay span {
  font-size       : 12px;
  color           : $white;
  padding         : 10px 5px;
  text-transform  : uppercase;
  background-color: $brand-primary;
}

figure.workItem {
  position: relative;
  overflow: hidden;
  width   : 50vw;
  height  : 50vh;
  margin  : 0;
}

figure.workItem a {
  display: block;
}

figure.workItem img {
  -webkit-transform : scale(1.0);
  transform         : scale(1.0);
  width             : 50vw;
  height            : 50vh;
  -o-object-fit     : cover;
  object-fit        : cover;
  -webkit-transition: -webkit-transform ease .5s;
  transition        : -webkit-transform ease .5s;
  transition        : transform ease .5s;
  transition        : transform ease .5s, -webkit-transform ease .5s;
}

figure.workItem:hover img {
  -webkit-transform : scale(1.05);
  transform         : scale(1.05);
  -webkit-transition: -webkit-transform ease .5s;
  transition        : -webkit-transform ease .5s;
  transition        : transform ease .5s;
  transition        : transform ease .5s, -webkit-transform ease .5s;
}

figure.workItem:nth-child(1),
figure.workItem:nth-child(1) img {
  width: 60vw;
}

figure.workItem:nth-child(2),
figure.workItem:nth-child(2) img {
  width: 40vw;
}

/* Break Section */
.breakSection {
  position        : relative;
  background-color: $accent-color;
}

.breakSectionWrapper {
  padding          : 4em 0;
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -ms-flex-pack    : distribute;
  justify-content  : space-around;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.breakIntro {
  text-align: center;
  max-width : 70%;
}

.breakTitle {
  text-transform: uppercase;
  color         : $white;
  margin        : 0;
  position      : relative;
  padding       : 10px 0;
  display       : inline-block;
}

.breakTitle:after {
  content : '';
  position: absolute;
  border  : $white 1px solid;
  width   : 35%;
  left    : 30%;
  top     : 90%;
}

/* Work Client Block */
.workClientBlock {
  background-color: #1E2128;
}

.workClientBlockWrapper {
  padding   : 6em 0;
  text-align: center;
}

.workClientBlockWrapper p {
  color    : $white;
  max-width: 70%;
  margin   : 2em auto;
}

.workClientBlockWrapper hr {
  color    : $white;
  height   : 4px;
  max-width: 5%;
  margin   : 2em auto;
}
/* Services Page Block*/
.serviceSection.servicePage {
  padding: 6em 0;
  .demo-icon{
    font-size: 10rem;
    color: $white;
    @include media-breakpoint-down(md) {
      font-size: 6rem;
    }
  }
  .icon-block{
    @include media-breakpoint-down(sm) {
     padding: 0 2.75rem;
    }
  }
  .workPageItem{
    & > div{
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
    h1:after{
      @include media-breakpoint-down(sm) {
        left: 0;
      }
    }
    &:nth-child(1){
      img{
        background-color: $brand-primary;
      }
    }
     &:nth-child(2){
      img{
        background-color: $accent-color;
      }
    }
     &:nth-child(3){
      img{
        background-color: #00af64;
      }
    }
    &:nth-child(4){
      img{
        background-color: #00304c;
      }
    }
     &:nth-child(5){
      img{
        background-color: #ff6c00;
      }
    }
    &:nth-child(6){
      img{
        background-color: #02b5a6;
      }
    }
  }
}

.servicesWrapper.servicesPage {
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
  -ms-flex-wrap    : wrap;
  flex-wrap        : wrap;
}

.servicePageItem {
  position               : relative;
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
  padding                : 2em 2em 2em 6em;
}

.servicePageItem i {
  position : absolute;
  top      : 0;
  left     : 0;
  font-size: 8em;
  z-index  : 1;
  color    : #ebebeb;
}

.servicePageItem [class*=" icon-"]:before,
.servicePageItem [class^="icon-"]:before {
  width : 1em;
  margin: 0;
}

.servicePageItem h3 {
  position: relative;
  z-index : 2;
}

.servicePageItem p {
  position : relative;
  font-size: 15px;
  z-index  : 2;
}

.servicePageItem ul {
  position  : relative;
  list-style: none;
  z-index   : 2;

}

.servicePageItem ul li {
  font-size     : 12px;
  font-weight   : 600;
  text-transform: uppercase;
  position      : relative;
  padding-left  : 15px;
  z-index       : 2;
}

.servicePageItem ul li::before {
  content : '-';
  position: absolute;
  left    : 0;
}

/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* MEDIA QUERIES */
@media (max-width:991px) {
  figure.workItem {
    width : 100% !important;
    height: 50vw;
  }

  figure.workItem img {
    width             : 100vw !important;
    height            : 50vw;
    -o-object-fit     : cover;
    object-fit        : cover;
    -webkit-transition: -webkit-transform ease .5s;
    transition        : -webkit-transform ease .5s;
    transition        : transform ease .5s;
    transition        : transform ease .5s, -webkit-transform ease .5s;
  }

  figcaption.workOverlay {
    position : absolute;
    width    : 100%;
    top      : 2em;
    left     : 2em;
    font-size: 14px;
  }
}
@media (max-width:767px) {
  .globalNavWrapper.container {
    margin: 0;
  }

  .calloutSectionWrapper {
    padding              : 1em 2em;
    -webkit-box-orient   : vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction   : column;
    flex-direction       : column;
    font-size            : 12px;
  }

  .mapWrapper #map {
    width : 100%;
    height: 50vw;
  }
}
@media (max-width:530px) {
  .serviceItem {
    text-align   : center;
    margin-bottom: 1em;
  }

  .quoteSectionWrapper {
    padding: 2em 0;
  }

  .serviceItemInner {
    padding: 0;
  }

  .serviceItem p {
    display: none;
  }

  .aboutSectionWrapper {
    padding: 4em 2em;
    height : auto;
  }

  figure.workItem {
    width : 100% !important;
    height: 70vw;
  }

  figure.workItem img {
    width             : 100vw !important;
    height            : 70vw;
    -o-object-fit     : cover;
    object-fit        : cover;
    -webkit-transition: -webkit-transform ease .5s;
    transition        : -webkit-transform ease .5s;
    transition        : transform ease .5s;
    transition        : transform ease .5s, -webkit-transform ease .5s;
  }

  figcaption.workOverlay {
    position : absolute;
    width    : 100%;
    top      : 2em;
    left     : 2em;
    font-size: 12px;
  }

  .clientMain {
    max-width: 100%;
  }

  .clientItem {
    -ms-flex-preferred-size: 33.333%;
    flex-basis             : 33.333%;
    padding                : 5px;
    position               : relative;
  }

  .clientItem img.greyScale {
    position          : absolute;
    width             : calc(100% - 10px);
    top               : 5px;
    left              : 5px;
    opacity           : 1;
    -webkit-transition: opacity ease .5s;
    transition        : opacity ease .5s;
  }

  .featuredWork {
    height   : auto;
    padding  : 1em 2em;
    font-size: 12px;
  }

  .calloutSectionWrapper {
    padding              : 1em 2em;
    -webkit-box-orient   : vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction   : column;
    flex-direction       : column;
    font-size            : 12px;
  }

  .servicePageItem {
    position               : relative;
    -ms-flex-preferred-size: 100%;
    flex-basis             : 100%;
    padding                : 2em;
  }
}

.backroundCat {
  position: relative;
}

.breakoutImage {
  z-index: 2;
}

.backroundCat * {
  z-index : 2;
  position: relative;
}

.backroundCat::after {
  content       : attr(data-cat);
  z-index       : 1;
  position      : absolute;
  text-transform: uppercase;
  font-size     : 14vw;
  left          : 0;
  padding       : 1vw;
  top           : -10vw;
  line-height   : 14vw;
  color         : rgba(228, 228, 228, .5);
  width         : 60vw;
  font-weight   : 600;
}

.dropshadow img {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);
}

.colOne hr,
.colTwo hr {
  width : 50%;
  margin: 0;
}

.videoSlideWrapper {
  width: 100%;
}
@media (max-width:767px) {
  .slick-next {
    right : 10%;
    top   : auto;
    bottom: 0;
  }

  .slick-prev {
    left  : 10%;
    top   : auto;
    bottom: 0;
  }
}

.videoSlide {
  position      : relative;
  padding-bottom: 56.25%;
  height        : 0;
  overflow      : hidden;
  max-width     : 100%;
  height        : auto;
  margin        : 1em 0 1.5em;
}

.videoSlide embed,
.videoSlide iframe,
.videoSlide object {
  position: absolute;
  top     : 0;
  left    : 0;
  width   : 100%;
  height  : 100%;
}

/*Priority Auto Work Page*/

/*Dunkin Donuts Work Page*/

.dunkin-donuts .workClientBlock {
  position      : relative;
  background    : #ea5ca4;
  background    : linear-gradient(to bottom, #ea5ca4 0%,#cf2e7e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#ea5ca4', endColorstr='#cf2e7e',GradientType=0 );
  padding-bottom: 10em;
  z-index       : 2;
}

.dunkin-donuts .workClientBlock::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  bottom           : -4vw;
  left             : 0;
  height           : 8vw;
  background-color : $white;
  -webkit-transform: skewy(4deg);
  transform        : skewy(4deg);
}

.dunkin-donuts .workIntroSection {
  position: relative;
}

.dunkin-donuts .workIntroSection:before {
  content         : '';
  width           : 100%;
  position        : absolute;
  height          : 100%;
  background-color: $white;
  z-index         : 1;
}

.dunkin-donuts .workIntroSectionWrapper {
  padding : 0 0 20em;
  margin  : -10em auto 0;
  z-index : 3;
  position: relative;
}

.dunkin-donuts .workIntroSectionWrapper .copySection {
  text-align: left;
  max-width : 50%;
  margin    : -3em 0 2em;
}

.dunkin-donuts .videoSlide {
  margin    : 0 auto 4em;
  text-align: center;
}

.dunkin-donuts .offsetImage {
  z-index : 2;
  position: relative;
  right   : -16vw;
}

.dunkin-donuts .workSectionOne {
  background: $white;
}

.dunkin-donuts .workSectionOneWrapper {
  padding          : 10em 0 20em;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.dunkSocial {
  position: relative;

  .fblike {
    position: absolute;
    right   : 0;
    bottom  : 0;
    z-index : -1;
    width   : 50%;
  }
}

.dunkin-donuts .workSectionOneWrapper .colOne,
.dunkin-donuts .workSectionOneWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.dunkin-donuts .workSectionOneWrapper .colTwo {
  padding: 0 11vw 0 6vw;
}

.dunkin-donuts .workSectionTwo {
  background: #f48221;
}

.dunkin-donuts .workSectionTwoWrapper {
  padding          : 12em 0;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.dunkin-donuts .workSectionFour {
  background: #de4892;
  position  : relative;
  color     : $white;
}

.dunkin-donuts .workSectionFour h2 {
  color: $white;
}

.dunkin-donuts .workSectionFour hr {
  background-color: $white;
}

.dunkin-donuts .workSectionFour .backroundCat::after {
  color: #d8428a;
}

.dunkin-donuts .workSectionFour::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  top              : -6vw;
  left             : 0;
  height           : 12vw;
  background-color : #f4f4f4;
  -webkit-transform: skewy(-6deg);
  transform        : skewy(-6deg);
  z-index          : 1;
}

.dunkin-donuts .workSectionFourWrapper {
  padding          : 0 0 8em;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.dunkin-donuts .workSectionFourWrapper .colOne {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.dunkin-donuts .workSectionFourWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
  align-self             : flex-end;
}

.dunkin-donuts .workSectionFourWrapper .colTwo {
  padding: 0 4em;
}
/*CHKD Work Page*/

.chkd .workClientBlock {
  position      : relative;
  background    : #1690bf;
  background    : -webkit-linear-gradient(top, #1690bf 0%,#1a639a 100%);
  background    : linear-gradient(to bottom, #1690bf 0%,#1a639a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#1690bf', endColorstr='#1a639a',GradientType=0 );
  padding-bottom: 10em;
  z-index       : 2;
}

.chkd .workClientBlock::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  bottom           : -4vw;
  left             : 0;
  height           : 8vw;
  background-color : $white;
  -webkit-transform: skewy(4deg);
  transform        : skewy(4deg);
}

.chkd .workIntroSection {
  position: relative;
}

.chkd .workIntroSection:before {
  content         : '';
  width           : 100%;
  position        : absolute;
  height          : 100%;
  background-color: $white;
  z-index         : 1;
}

.chkd .workIntroSection::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  bottom           : -8vw;
  left             : 0;
  height           : 16vw;
  background-color : #c0166f;
  -webkit-transform: skewy(8deg);
  transform        : skewy(8deg);
  z-index          : 1;
}

.chkd .workIntroSectionWrapper {
  padding : 0 0 20em;
  margin  : -10em auto 0;
  z-index : 3;
  position: relative;
}

.chkd .workIntroSectionWrapper .copySection {
  text-align: center;
  max-width : 767px;
  margin    : 5em auto 2em;
  padding   : 6em;
}

.chkd .videoSlide {
  text-align: center;
}

.chkd .workSectionOne {
  background: #c0166f;
  color     : $white;
}

.chkd .workSectionOne h2 {
  color: $white;
}

.chkd .workSectionOne hr {
  background-color: $white;
}

.chkd .workSectionOne .backroundCat::after {
  color: #ba166c;
}

.chkd .workSectionOneWrapper {
  padding              : 0 0 25em;
  margin               : -18em auto 0;
  position             : relative;
  z-index              : 2;
  /* Flex */
  display              : -webkit-box;
  display              : -ms-flexbox;
  display              : flex;
  -webkit-box-pack     : center;
  -ms-flex-pack        : center;
  justify-content      : center;
  -webkit-box-align    : start;
  -ms-flex-align       : start;
  align-items          : flex-start;
  -webkit-box-orient   : vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction   : column;
  flex-direction       : column;
}

.chkd .workSectionOneWrapper .colOne {
  margin: 2em 0;
}

.chkd .workSectionOneWrapper .colTwo {
  -ms-flex-item-align: end;
  align-self         : flex-end;
  width              : 50%;
  padding            : 0 11vw 0 6vw;
}

.chkd .workSectionTwo {
  background: #019af7;
}

.chkd .workSectionTwoWrapper {
  padding          : 12em 0;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.chkd .numberCol {
  position: relative;
  padding : 1em;
}

.chkd .numberCol span {
  font-size  : 7em;
  font-weight: 900;
  position   : relative;
  color      : $white;
  opacity    : .8;
  top        : 1.5rem;
  z-index    : -1;
}

.chkd .numberCol h2 {
  padding  : 0 1em 1em;
  color    : $white;
  position : relative;
  z-index  : 1;
  font-size: 1.5rem;
}

.chkd .numberCol h2:after {
  content           : '';
  position          : absolute;
  height            : 2px;
  background-color  : $white;
  top               : 75%;
  left              : 1em;
  width             : 50%;
  z-index           : 3;
  -webkit-transition: all ease .5s;
  transition        : all ease .5s;
}

.chkd .workSectionThree {}

.chkd .workSectionThree {
  background: $white;
  position  : relative;
}

.chkd .workSectionThree::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  top              : -8vw;
  left             : 0;
  height           : 16vw;
  background-color : $white;
  -webkit-transform: skewy(8deg);
  transform        : skewy(8deg);
  z-index          : 1;
}

.chkd .workSectionThreeWrapper {
  padding          : 0 0 10em;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.chkd .workSectionThreeWrapper .colOne,
.chkd .workSectionThreeWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.chkd .workSectionThreeWrapper .colOne {
  padding-right: 2em;
}

.chkd .workSectionThreeWrapper .colTwo {
  padding-left: 2em;
}

.chkd .workSectionFour {
  position: relative;
}

.chkd .workSectionFourWrapper {
  padding          : 16em 0 8em;
  margin           : 0 auto 8em;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.chkd .workSectionFourWrapper .colOne,
.chkd .workSectionFourWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.chkd .workSectionFourWrapper .colOne {
  padding-right: 2em;
}

.chkd .workSectionFourWrapper .colTwo {
  padding-left: 2em;
}

.chkd .workSectionFive {
  position        : relative;
  background-color: #6ea224;
  color           : $white;
}

.chkd .workSectionFive h2 {
  color: $white;
}

.chkd .workSectionFive hr {
  background-color: $white;
}

.chkd .workSectionFive .backroundCat::after {
  color: #71a826;
}

.chkd .workSectionFiveWrapper {
  padding          : 0;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.chkd .workSectionFiveWrapper .colOne,
.chkd .workSectionFiveWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.chkd .workSectionFiveWrapper .colTwo {
  padding-right: 2em;
  margin-top   : -4em;
  text-align   : center;
}

.chkd .workSectionFiveWrapper .colOne {
  padding-left: 2em;
}

/*adsinc Work Page*/

.adsinc .workClientBlock {
  position      : relative;
  background    : #2e2e2e;
  background    : linear-gradient(to bottom, #2e2e2e 0%,#0e0e0e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#2e2e2e', endColorstr='#0e0e0e',GradientType=0 );
  padding-bottom: 10em;
  z-index       : 2;
}

.adsinc .workClientBlock::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  bottom           : -4vw;
  left             : 0;
  height           : 8vw;
  background-color : $white;
  -webkit-transform: skewy(4deg);
  transform        : skewy(4deg);
}

.adsinc .workIntroSection {
  position: relative;
}

.adsinc .workIntroSection:before {
  content         : '';
  width           : 100%;
  position        : absolute;
  height          : 100%;
  background-color: $white;
  z-index         : 1;
}

.adsinc .workIntroSectionWrapper {
  padding : 0 0 20em;
  margin  : -10em auto 0;
  z-index : 3;
  position: relative;
}

.adsinc .workIntroSectionWrapper .copySection {
  text-align: center;
  max-width : 767px;
  margin    : 5em auto 2em;
  padding   : 6em;
}

.adsinc .videoSlide {
  text-align: center;
}

.adsinc .workSectionOne {
  background: #e1e1e1;
}

.adsinc .workSectionOneWrapper {
  padding              : 0 0 18em;
  margin               : -18em auto 0;
  position             : relative;
  z-index              : 2;
  /* Flex */
  display              : -webkit-box;
  display              : -ms-flexbox;
  display              : flex;
  -webkit-box-pack     : center;
  -ms-flex-pack        : center;
  justify-content      : center;
  -webkit-box-align    : start;
  -ms-flex-align       : start;
  align-items          : flex-start;
  -webkit-box-orient   : vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction   : column;
  flex-direction       : column;
}

.adsinc .workSectionOneWrapper .colOne {
  margin: 2em 0;
}

.adsinc .workSectionOneWrapper .colOne {
  width: 100%;
}

.adsinc .workSectionOneWrapper .colOne img {
  width: 100%;
}

.adsinc .workSectionOneWrapper .colTwo {
  -ms-flex-item-align: end;
  align-self         : flex-end;
  width              : 50%;
  padding            : 0 11vw 0 6vw;
}

.adsinc .workSectionTwo {
  background: #97b106;
}

.adsinc .workSectionTwoWrapper {
  padding          : 12em 0;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.adsinc .numberCol {
  position: relative;
  padding : 1em;
}

.adsinc .numberCol span {
  font-size  : 7em;
  font-weight: 900;
  position   : relative;
  color      : $white;
  opacity    : .8;
  top        : 1.5rem;
  z-index    : -1;
}

.adsinc .numberCol h2 {
  padding  : 0 1em 1em;
  color    : #171717;
  position : relative;
  z-index  : 1;
  font-size: 1.5rem;
}

.adsinc .numberCol h2:after {
  content           : '';
  position          : absolute;
  height            : 2px;
  background-color  : #171717;
  top               : 75%;
  left              : 1em;
  width             : 50%;
  z-index           : 3;
  -webkit-transition: all ease .5s;
  transition        : all ease .5s;
}

.adsinc .workSectionThree {}

.adsinc .workSectionThree {
  background         : $white;
  position           : relative;
  background-size    : cover;
  background-position: center;
  color              : $white;
}

.adsinc .workSectionThree::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  top              : -8vw;
  left             : 0;
  height           : 16vw;
  background-color : #e1e1e1;
  -webkit-transform: skewy(8deg);
  transform        : skewy(8deg);
  z-index          : 1;
}

.adsinc .workSectionThreeWrapper {
  padding          : 50em 0 10em;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.adsinc .workSectionThreeWrapper .colOne,
.adsinc .workSectionThreeWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.adsinc .workSectionThreeWrapper .colOne {
  padding-right: 2em;
  margin-left  : 50%;
}

.adsinc .workSectionThreeWrapper .colTwo {
  padding-left: 2em;
}

.adsinc .workSectionFour {
  position: relative;
}

.adsinc .workSectionFour::after {
  content          : '';
  position         : absolute;
  width            : 100%;
  top              : -8vw;
  left             : 0;
  height           : 16vw;
  background-color : #97b106;
  -webkit-transform: skewy(8deg);
  transform        : skewy(8deg);
  z-index          : 1;
}

.adsinc .workSectionFourWrapper {
  padding          : 4em 0 12em;
  margin           : 0 auto 8em;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.adsinc .workSectionFourWrapper .colOne,
.adsinc .workSectionFourWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.adsinc .workSectionFourWrapper .colOne {
  padding-right      : 2em;
  -ms-flex-item-align: end;
  align-self         : flex-end;
}

.adsinc .workSectionFourWrapper .colTwo {
  padding-left: 2em;
}

.adsinc .workSectionFive {
  position        : relative;
  background-color: #f4f4f4;
}

.adsinc .workSectionFiveWrapper {
  padding          : 8em 0;
  margin           : 0 auto;
  position         : relative;
  z-index          : 2;
  /* Flex */
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-pack : center;
  -ms-flex-pack    : center;
  justify-content  : center;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.adsinc .workSectionFiveWrapper .colOne,
.adsinc .workSectionFiveWrapper .colTwo {
  -ms-flex-preferred-size: 50%;
  flex-basis             : 50%;
}

.adsinc .workSectionFiveWrapper .colOne {
  padding-right: 2em;
  margin-top   : -4em;
  text-align   : center;
}

.adsinc .workSectionFiveWrapper .colTwo {
  padding-left: 2em;
}

.clientItemWrapper,
.peopleItemWrapper,
.servicesWrapper {
  width: 100%;
}

.clientItem {
  padding: 0;
}

.clientItem img.greyScale {
  width: calc(100%);
  top  : 0;
  left : 0;
}

/* Work Section Homepage - IE Updates */
figure.workItem {
  width: 50%;
}

figure.workItem img {
  width: 100%;
}

figure.workItem:nth-child(1) {
  width: 60%;
}

figure.workItem:nth-child(2) {
  width: 40%;
}

figure.workItem:nth-child(1) img,
figure.workItem:nth-child(2) img {
  width: 100%;
}

.flipped:before{
  transform: rotate(180deg);
}
// /*About text*/
// .aboutText{
//   width: 30%;
// }
// .aboutPage.aboutIntro{
//   margin-left: 10%;
// }

/*Fix Padding on contact page*/
