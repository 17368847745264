.big-p {
  font-size    : 1.25rem;
  margin-bottom: 2rem;
  line-height  : 1.75;
  color        : lighten($text,10%);
  font-weight  : 300;
}

.thin {
  font-weight: 300;
}

body {
  font-family: $font-heading;
}

p {
  font-family: $font-body;
}

////////////////////////
// Seperators
////////////////////////

%seperator-base {
  background: $brand-primary;
  margin    : 2rem auto 1rem 0;
  display   : none;
  height    : 2px;
  padding   : 0;
  width     : 100%;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.seperator {
  // Width
  &__sm {
    @extend %seperator-base;
    width: 4rem;

    &--white {
      @extend .seperator__sm;
      background: $white;
      margin    : 2rem auto 1rem;
    }

    &--gray {
      @extend .seperator__sm;
      background: $grey;
    }

    &.left {
      margin: 2rem 0 1rem;
    }
  }

  &__md {
    @extend %seperator-base;
    width: 8rem;

    &--white {
      @extend .seperator__md;
      background: $white;
    }

    &--gray {
      @extend .seperator__md;
      background: $grey;
    }

    &.left {
      margin: 2rem 0 1rem;
    }
  }

  &__lg {
    @extend %seperator-base;
    width: 12rem;

    &--white {
      @extend .seperator__lg;
      background: $white;
    }

    &--gray {
      @extend .seperator__lg;
      background: $grey;
    }

    &.left {
      margin: 2rem 0 1rem;
    }
  }
}

hr.partial {
  display      : inline-block;
  width        : 8rem;
  margin-top   : .5rem;
  margin-bottom: .5rem;
  border-top   : 2px solid $brand-primary;
}

h2 {
  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.15rem;
  }
}

h3 {
  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}
