@charset 'UTF-8';

/* Slider */
.slick-slider {
  position                   : relative;
  display                    : block;
  box-sizing                 : border-box;
  user-select                : none;
  -webkit-touch-callout      : none;
  -khtml-user-select         : none;
  -ms-touch-action           : pan-y;
  touch-action               : pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display : block;
  overflow: hidden;
  margin  : 0;
  padding : 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top     : 0;
  left    : 0;
  display : block;

  &:after,
  &:before {
    display: table;
    content: '';
  }

  &:after {
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display   : none;
  float     : left;
  height    : 100%;
  min-height: 1px;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height : auto;
  border : 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-next,
.slick-prev {
  font-size  : 0;
  line-height: 0;
  position   : absolute;
  top        : 50%;
  display    : block;
  width      : 2rem;
  height     : 2rem;
  padding    : 0;
  transform  : translate(0%, -50%);
  cursor     : pointer;
  color      : transparent;
  border     : none;
  outline    : none;
  background : transparent;

  &:focus,
  &:focus,
  &:hover,
  &:hover {
    color     : transparent;
    outline   : none;
    background: transparent;
  }

  &:focus:before,
  &:focus:before,
  &:hover:before,
  &:hover:before {
    opacity: 1;
  }
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: .25;
}

.slick-next:before,
.slick-prev:before {
  font-size              : 2rem;
  line-height            : 1;
  font-family            : 'fontello';
  font-style             : normal;
  font-weight            : normal;
  speak                  : none;
  opacity                : .75;
  color                  : white;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left            : - 2.5rem;
  transform-origin: top;
  display         : inline-block;

  &:before {
    content  : '\e819';
    transform: rotateY(180deg);
    display  : block;
    padding  : 3px 5px;
  }

  &:hover {
    padding-right: .75rem;
    transition   : all ease .5s;
  }
}

.slick-next {
  right     : - 2.5rem;
  transition: all ease .5s;

  &:before {
    content: '\e819';
    width  : 38px;
    height : 38px;
  }

  &:hover {
    padding-left: .75rem;
    transition  : all ease .5s;
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position  : absolute;
  bottom    : -25px;

  display   : block;

  width     : 100%;
  padding   : 0;
  margin    : 0;

  list-style: none;

  text-align: center;
}

.slick-dots li {
  position: relative;

  display : inline-block;

  width   : 20px;
  height  : 20px;
  margin  : 0 5px;
  padding : 0;

  cursor  : pointer;
}

.slick-dots li button {
  font-size  : 0;
  line-height: 0;

  display    : block;

  width      : 20px;
  height     : 20px;
  padding    : 5px;

  cursor     : pointer;

  color      : transparent;
  border     : 0;
  outline    : none;
  background : transparent;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size              : 6px;
  line-height            : 20px;
  position               : absolute;
  top                    : 0;
  left                   : 0;
  width                  : 20px;
  height                 : 20px;
  content                : '•';
  text-align             : center;
  opacity                : .25;
  color                  : black;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color  : black;
}
