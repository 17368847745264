// Search form

.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// GRAVITY CSS
/* time ​fields */
.clear-multi {
  @extend .row;

  i {
    display: none;
  }
}

.gform_wrapper .gfield_time_ampm,
.gform_wrapper .gfield_time_hour,
.gform_wrapper .gfield_time_minute {
  @extend .col-sm-4;
}

// Gravity
.gform_fields {
  @extend .list-unstyled;

  input,
  select,
  textarea {
    @extend .form-control;
  }

  textarea {
    height: auto;
  }

}

.ginput_container {
  @extend .form-group;
  width: 100%;
}

.gfield_description {
  @extend .alert;
}

.validation_error {
  @extend .alert;
  @extend .alert-danger;
}

.validation_message {
  @extend .alert;
  @extend .alert-warning;

  &.instruction {}
}

.gforms_confirmation_message {
  @extend .alert;
  @extend .alert-success;
}
//
.gform_button {
  @extend .btn;
  @extend .btn-primary;
}

.gfield_required {
  color: $alert-danger-text;
}

.gform_wrapper ul.gfield_checkbox li,
.gform_wrapper ul.gfield_radio li {
  @extend .form-check;
}

.gform_validation_container {
  display: none;
}

.gfield_error .ginput_container {
  margin-bottom: 10px;
}

.ginput_complex {
  display     : flex;
  margin-left : 0;
  margin-right: 0;
  flex-wrap   : wrap;

  span {
    @extend .col-sm-12, .col-md-6;
    padding-left: 0;

    &:nth-child(2) {
      padding-right: 0;
    }

    label {
      font-size     : 10px;
      text-transform: uppercase;
      color         : #888;
      letter-spacing: 2px;
      margin-bottom : 0;
    }
  }
}

#gforms_confirmation_message {
  @extend .alert;
  @extend .alert-success;
}

// Additional
.gform_title {
  text-transform: uppercase;
  color         : $brand-primary;
}

/* G Form*/
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  width: 100%;
}

.gform_wrapper .top_label .gfield_label {
  color         : #bdbdbd;
  font-weight   : 300;
  text-transform: uppercase;
  letter-spacing: .25em;
  font-size     : 12px;
}
.gfield_description:not(.validation_message) {
  color         : #bdbdbd;
  font-weight   : 300;
  text-transform: uppercase;
  letter-spacing: .25em;
  font-size     : 12px;
  padding       : 0;
}

.gform_wrapper .gfield_required {
  color: #e8e8e8 !important;
}

input {
  border-radius: 6px !important;
  border       : 2px solid #d6d6d6 !important;
}

input:focus {
  color       : #333333;
  border-color: $accent-color !important;
  box-shadow  : 0 0 2px $accent-color;
}

textarea {
  border-radius: 6px !important;
  border       : 2px solid #d6d6d6 !important;
}

textarea:focus {
  color       : #333333;
  border-color: $accent-color !important;
  box-shadow  : 0 0 2px $accent-color;
}

.formTitle h2 {
  font-weight: 900;
  color      : #333;
}
.hide-validation {

  .validation_message{
    display: none;
  }
  .gfield_description.validation_message{
    display: block;
  }
}
.gfield_visibility_hidden{
  visibility: hidden;
  height: 0;
}
