.post-masthead{
    padding: 4rem;
    @include media-breakpoint-up(md) {
        padding: 6rem;
    }
}
blockquote{
    padding: .25rem 2rem;
    border-left: .75rem solid $brand-primary;
    font-style: italic;
    font-size: 1.25rem;
    color: #635e5e;
    margin: 2rem 0;
    @include media-breakpoint-up(sm) {
        margin: 2rem 3rem 2rem -3rem;
    }
    p{
        margin: 0;
    }
}
.type-post{
    margin-bottom: 8rem;
    font-family: $font-body;
    .title-main{
        h1{
            font-size: 2.5rem;
            text-transform: none;
            line-height: 2.5rem;
        }
    }
    .blog-title{
        padding-bottom: 2rem;
    }
    .entry-content{
        line-height: 1.7rem;
       @include media-breakpoint-up(md) {
            margin-top: 6rem;
        }
        .entry-content-asset {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 35px;
            height: 0;
            overflow: hidden;
            margin-bottom: 1.5rem;
            background: $grey;
            iframe {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .avatar{
        width:100%;
        height: auto;
    }
    img{
        width: 100%;
        height: auto;
    }
    figcaption{
        text-align: center;
        padding: 1rem;
    }
    .byline{
        margin-bottom: 0;
    }
}

%grey-box{
    // padding      : 2em;
    // border       : 1px solid $grey;
    // background   : #ebebeb;
    border-radius: .25rem;
    margin-bottom: 2rem;
}

.blog{
    .type-post{
       padding:4rem;
       margin-bottom: 0;
        .title-sub{
            color: $dark-grey;
        }
    }
    .workPageItem{
        h1{
            font-size: 1.7em;
            margin-bottom: .5rem;
        }
        &::after {
            content: none !important;
        }
        .work-overlay-inner{
            @include media-breakpoint-down(sm) {
                text-align: left;
            }
             h1:after{
                @include media-breakpoint-down(sm) {
                    left: 0;
                }
            }
        }
        .overlay-bg{
            @include media-breakpoint-down(sm) {
                padding: 0 2.75rem;
            }
        }
    }
}

.category{
   @extend .blog;
}

.category-menu{
    padding: .5rem 0;
    border-bottom: 2px solid $brand-primary;
    margin-bottom: 2rem;
    .nav{
        display: block;
        .cat-item{
            margin-right: 1rem; 
            display: inline;
            a{
                padding      : .5em 1rem;
                border       : 1px solid $grey;
                background   : $grey;
                color: $dark-grey;
                border-radius: .25rem;
                margin-bottom: .75rem;
                display: inline-block;
            }
            &.current-cat{
                a{
                background   :$accent-color;
                color: $white;
                }
            }
            &:hover{
                a{
                background   :$accent-color;
                color: $white;
                }
            }

        }
    }
}

.author-block{
    margin-top: 2rem;
    hr.partial{
        display: inline-block;
        width: 100%;
        margin-top: .5rem;
        margin-bottom: 1.5rem;
        border-top: 2px solid $brand-primary;
    }
    .title-sub{
        margin-bottom: 0; 
        @include media-breakpoint-down(md) {
            margin-top: 1rem;
         }
    }
    .author-title{
        display: block;
        margin-bottom: 1rem;
    }
    p{
        font-size: 0.75rem;
    }
}

.news-feed{
    .entry-title{
        margin-bottom: 1.5rem;
    }
}

pre{
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 90%;
    padding: 1rem 2rem;
    background: #292b2c;
    color: #d4d0d0;
    border-radius: 0.5rem;
}