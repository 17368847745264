/////////////////////
// Footer
/////////////////////
.site-footer {
  background: $dark-bg;
  color     : #b2b8c6;
  margin-top: 0;
  border    : none;
  padding   : 6em 0;
  text-align: center;
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

.footer-social {
  padding-top    : 2em;
  padding-bottom : 2em;
  justify-content: center;
  @include media-breakpoint-up(md) {
    justify-content: left;
    border-bottom  : 1px solid $white;
  }

  a {
    padding        : 10px;
    color          : $white;
    text-decoration: none;
    box-shadow     : none;
    font-size      : 1.5em;

    &:last-child{
      margin-left: auto;
       @include media-breakpoint-down(sm) {
        width: 100%;
       }
    }

    &:hover {
      color          : $brand-primary;
      text-decoration: none;
      box-shadow     : none;
    }
  }
}

.footer-location {
  padding-top   : 2em;
  padding-bottom: 1em;
  @include media-breakpoint-up(md) {
    padding-top   : 4em;
    padding-bottom: 2em;
  }
}

.logo-col {
  order        : 0;
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    order: 2;
  }

  .logo-footer {
    max-width: 100px;
  }
}

.location {
  margin-bottom: 2rem;
  order        : 2;
  @include media-breakpoint-up(md) {
    order: 0;
  }

  h3 {
    color        : $white;
    font-weight  : 600;
    margin-bottom: .1em;
  }

  .numberLocationSplit {
    padding: 1em 0;
  }

}

.phone {
  padding        : .5em 1em;
  text-decoration: none;
  border         : 1px solid $brand-primary;
  border-radius  : 40px;
  box-shadow     : none;
  color          : $white;
  display        : inline-block;
  transition     : all .5s ease;
  position       : relative;
  z-index        : 1;

  &:after {
    background   : $brand-primary;
    position     : absolute;
    top          : 0;
    left         : 0;
    height       : 100%;
    width        : 0;
    content      : '';
    border-radius: 40px;
    transition   : all .5s ease;
    opacity      : 0;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
    color     : $white;
    transition: all .5s ease;

    &:after {
      width     : 100%;
      opacity   : 1;
      transition: all .5s ease;
      z-index   : -1;
    }
  }

  span {
    margin-left : .75em;
    padding-left: .75em;
    border-left : 1px solid $brand-primary;
  }
}

.locationLink {
  margin    : 0 1em;
  padding   : .5em 1em;
  color     : $brand-primary;
  box-shadow: none;
  position  : relative;

  &:hover {
    color     : $brand-primary;
    box-shadow: none;
  }
}

.locationLink span {
  margin-left : .75em;
  padding-left: .75em;
}

.locationLink:after {
  content           : '';
  background        : $brand-primary;
  width             : 0;
  height            : 2px;
  position          : absolute;
  left              : 1.5em;
  bottom            : 0;
  -webkit-transition: width ease .3s;
  transition        : width ease .3s;
}

.locationLink:hover:after {
  width     : calc(100% - 3em);
  transition: width ease .3s;
}

.locationLink:focus:after {
  width     : calc(100% - 3em);
  transition: width ease .3s;
}
