// Grid settings
$enable-flex : true;
$main-sm-columns : 12;
$sidebar-sm-columns : 4;

// Colors
$red : #da2128;
$red : #DD0031;
$orange : #f7941d;
$yellow : #fed332;
$black : #181717;
$black-1 : #2A2A2A;
$grey : #D8D8D8;
$dark-grey : #999;
$text : #3C3C3C;
$white : #ffffff;
$blue : #2d6cca;
$dark-bg : #1E2128;
$brand-primary : $red;
$primary : $brand-primary;
$accent-color : $blue;

$law : #202a45;
$issue : #2192c7;
$priority : #cb1b1b;
$arbys : #d71a21;
$dunkin : #f48221;
$chkd : #019af7;
$ads : #97B106;
$light : #f4f4f4;
$rnr : #ca282c;
$change: #eb1c24;

// Fonts
$icon : 'fontello';

$font-family-sans-serif: "Roboto",
"Lato",
-apple-system,
system-ui,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif !default;
$font-family-serif : Georgia,
"Times New Roman",
Times,
serif !default;
$font-family-monospace : Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;

$font-heading : "Montserrat",
sans-serif;
$font-body : "Roboto",
sans-serif;
$font-family-base : $font-family-sans-serif;
