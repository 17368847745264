/////////////////////
// About Page
////////////////////
.work-intro {
  padding-top   : 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top   : 5rem;
    padding-bottom: 5rem;
  }
}

.section-about {
  background-color: #f4f4f4;
  position        : relative;
}

.title-main {
  color        : #df1e36;
  margin-bottom: .5em;
  position     : relative;
  z-index      : 1;

  h1 {
    font-size     : 5rem;
    text-align    : right;
    line-height   : .8em;
    font-weight   : 900;
    text-transform: uppercase;
    color         : #df1e36;
    @include media-breakpoint-up(md) {
      font-size: 5.5rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 7.5rem;
    }

  }

}

.title-sub {
  padding       : 0;
  text-transform: uppercase;
  font-size     : 1rem;
  font-weight   : 700;
  margin-bottom : 2rem;

}

.title-text {
  position: relative;
  @include media-breakpoint-up(md) {
    margin-top: 6rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 8rem;
  }

  .inner {
    z-index : 1;
    position: relative;
  }

  &:after {
    z-index    : -1;
    content    : 'DAVIS';
    position   : absolute;
    font-weight: 800;
    line-height: 1em;
    color      : rgba($grey,.2);
    word-break : break-all;
    z-index    : 0;
    display    : none;
    font-size  : 12rem;
    top        : -6rem;
    left       : 3rem;
    @include media-breakpoint-up(md) {
      display: block;
    }
    @include media-breakpoint-up(lg) {
      font-size: 16rem;
      top      : -10rem;
    }
  }
}

//////////////////////////////////
// People Section
//////////////////////////////////

#closer {
  position  : fixed;
  z-index   : -9000;
  top       : 15px;
  right     : 15px;
  padding   : 15px;
  height    : 44px;
  color     : tranparent;
  width     : 44px;
  opacity   : 0;
  visibility: hidden;
  transition: all .2s cubic-bezier(0, 0, 0.2, 1);

  &.active {
    z-index   : 9000;
    visibility: visible;
    opacity   : 1;
  }

  .bar {
    background  : transparent;
    border-color: $brand-primary;
    opacity     : 0;
    visibility  : hidden;

    &:after,
    &:before {
      border-color: $brand-primary;
    }

    &.change {
      opacity   : 1;
      visibility: visible;
      transition: all .2s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
.people{
   background-color: $dark-bg;
}
.person {
  background-color: $dark-bg;
  cursor          : pointer;
  h3 {
    color         : $brand-primary;
    &:after {
      left        : 0;
    }
  }
  .hover-icon {
    @include media-breakpoint-up(md) {
      opacity     : 0;
      transition  : opacity 0.5s ease;
      position    : absolute;
      top         : .5rem;
      right       : .5rem;
      color       : red;
      transform   : rotate(-90deg);
      font-size   : 1.34rem;
    }
  }
  .hover-overlay {
    h3 {
      padding     : 0;
      margin      : 0;
      text-shadow : 0 0 40px $black;
      &:after {
        content   : none;
        padding   : 0;
        margin    : 0;
      }
    }
    h5 {
      text-shadow : 0 0 40px $black;
    }
  }
  &:hover {
    z-index       : 20;
    &>img {
      @include media-breakpoint-up(md) {
        // opacity: 0.5;
      }
    }
    .hover-overlay {
      @include media-breakpoint-up(md) {
        opacity   : 1;
        transition: opacity 0.5s ease;
      }
    }
    .hover-icon {
      @include media-breakpoint-up(md) {
        opacity   : 1;
        transition: opacity 0.5s ease;
      }
    }
  }
}

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform        : translateZ(-1400px);
    opacity          : 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform        : translateZ(0);
    opacity          : 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform        : translateZ(-1400px);
    opacity          : 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform        : translateZ(0);
    opacity          : 1;
  }
}
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }

  100% {
    -webkit-transform: translateY(-1000px);
    transform        : translateY(-1000px);
    opacity          : 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }

  100% {
    -webkit-transform: translateY(-1000px);
    transform        : translateY(-1000px);
    opacity          : 0;
  }
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform        : translateY(-1000px);
    opacity          : 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform        : translateY(-1000px);
    opacity          : 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }
}

.person-overlay-bg {
  position  : fixed;
  z-index   : 7000;
  max-width : 100%;
  background: rgba($white, 0.95);
  display   : block;
  overflow  : auto;
  top       : 0;
  left      : 0;
  height    : 100%;
  animation : slide-out-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all 1s ease;

  .open & {
    height    : 100%;
    width     : 100%;
    opacity   : 1;
    animation : slide-in-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    transition: all 1s ease;
  }

  .closed & {
    // animation : slide-out-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    transition: all 1s ease;
    opacity   : 0;
  }
}

.person-overlay {
  position  : fixed;
  visibility: hidden;
  z-index   : 8000;
  width     : 100%;
  height    : 100%;
  top       : 0;
  left      : 0;
  overflow-y: scroll;
  animation : slide-out-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all 1s ease;

  .container,
  .row {
    height: 100%;
    height: 100vh;

  }

  .active & {
    display   : block;
    opacity   : 1;
    visibility: visible;
    animation : slide-in-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    transition: all 1s ease;
  }
}

.hover-overlay {
    padding: 1rem;
    @include media-breakpoint-up(md) {
      opacity: 1;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    h3{
      font-size: 1.25rem;
      font-weight: 300;
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
        padding-top: 0;
        margin-bottom: 0.5rem;
      }
    }
    h5{
      color: $white;
      font-size: 1rem;
      font-weight: 300;
      @include media-breakpoint-down(sm) {
        font-size: .8rem;
      }
    }
}

/////////////////////
// Contact Page
////////////////////
#map-dc,
#map-vb {
  height: 400px;
  width : auto;
  margin: 20px auto;
}

.locations {
  background: $gray-lighter;
}
