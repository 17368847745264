.workSection {
  padding: 5em 1em;
}

.workPageItem {
  margin-bottom: 2em;
  @include media-breakpoint-up(lg) {
    margin-bottom: 7em;
  }
}

.workPageItem:nth-child(even) {
  flex-direction: row-reverse;
}

.workPageItem {
  * {
    position: relative;
    z-index : 2;
  }

  &:after {
    content    : '';
    z-index    : 1;
    position   : absolute;
    font-size  : 20rem;
    left       : 40%;
    font-weight: 800;
    line-height: 1em;
    color      : rgba(228, 228, 228, .5);
    @include media-breakpoint-up(lg) {
      font-size: 30rem;
    }
  }

  img:hover {
    transform : scale(1.01);
    box-shadow: 0 10px 20px -5px rgba(109, 105, 105, 0.7);
    transition: all ease .5s;
  }

  img:hover + .overlayImageWrap {
    transform : scale(1.01);
    transition: all ease .5s;
  }

  h1 {
    padding      : 0;
    color        : #333333;
    font-weight  : 600;
    font-size    : 3em;
    margin-bottom: 0;
    position     : relative;
    display      : inline-block;

    &:after {
      content : '';
      position: absolute;
      border  : $dark-grey 1px solid;
      width   : 50px;
      left    : 0;
      top     : -1rem;
    }
  }

  &:nth-child(even)::after {
    left: 0;
  }

  &:nth-child(even) h1::after {
    left : auto;
    right: 0;
  }
}

.workPageItem:nth-child(1)::after {
  content: '01';
}

.workPageItem:nth-child(2)::after {
  content: '02';
}

.workPageItem:nth-child(3)::after {
  content: '03';
}

.workPageItem:nth-child(4)::after {
  content: '04';
}

.workPageItem:nth-child(5)::after {
  content: '05';
}

.workPageItem:nth-child(6)::after {
  content: '06';
}

.workPageItem:nth-child(7)::after {
  content: '07';
}
.workPageItem:nth-child(8)::after {
  content: '08';
}
.workPageItem:nth-child(9)::after {
  content: '09';
}

.workPageItem:nth-child(even) > div {
  text-align: right;
}

.workPageItem img:not(.overlayImage) {
  width     : 100%;
  box-shadow: 0 1px 20px 0 rgba(109, 105, 105, 0.5);
  transition: all ease .5s;
  height    : auto;
}

.workPageItem .overlayImageWrap {
  pointer-events : none;
  position       : absolute;
  width          : 100%;
  height         : 100%;
  top            : 0;
  left           : 0;
  display        : flex;
  justify-content: center;
  align-items    : center;
  padding        : 4em;
  z-index        : 20;
}

.work-overlay {
  padding: 2.5rem;
   @include media-breakpoint-down(md) {
    padding: 3rem 0 2rem;
   }
  p {
    margin-bottom: 1em;
    @include media-breakpoint-up(lg) {
      margin-bottom: 4em;
    }
  }
  .work-overlay-inner{
     @include media-breakpoint-down(md) {
      text-align: left;
      h1{
        &:after{
          left:0;
        }
      }
    }
  }
}

/////////////////////////////
// Call to Action
/////////////////////////////
.work-cta {
  color         : $brand-primary;
  padding       : .75em 0;
  position      : relative;
  text-transform: uppercase;
  font-weight   : 600;
  margin-right  : 2.5em;
}

.work-cta:hover {
  color          : $brand-primary;
  text-decoration: none;
}

.work-cta [class^="icon-"]:before,
[class*=" icon-"]:before {
  width: 1em;
}

.work-cta .featuredWorkIcon {
  font-size: 2em;
  top      : .15em;
  position : relative;
}

.work-cta i {
  position  : absolute;
  transition: all ease .5s;
}

.work-cta:hover i {
  transform : translateX(.25em);
  transition: all ease .5s;
}

///////////////////////
// Work Overview
///////////////////////
.overlay-bg {
  position       : relative;
  display        : flex;
  justify-content: center;
  align-items    : center;

  &:after {
    top     : 0;
    left    : 0;
    content : '';
    height  : 100%;
    width   : 100%;
    display : block;
    position: absolute;
    opacity : .8;
    z-index : 10;

    .priority-automotive & {
      background: $black;
    }

    .issue-advocacy & {
      background: $issue;
    }

    .dunkin-donuts & {
      background: $dunkin;
    }

    .ads & {
      background: $ads;
    }

    .law-firm-marketing & {
      background: $law;
    }

    .chkd & {
      background: $chkd;
    }

    .arbys & {
      background: $arbys;
    }
  }
}
