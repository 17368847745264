
/* Masthead */
.masthead {
  position  : relative;
  height    : 70vh;
  opacity   : 1;
  overflow  : hidden;
  color     : $white;
  background: $black;

  // &:before {
  //   content         : '';
  //   position        : absolute;
  //   width           : 100%;
  //   bottom          : -2vw;
  //   left            : 0;
  //   height          : 8vw;
  //   background-color: $white;
  //   transform       : skewy(2deg);
  //   z-index         : 4;
  // }
  h1 {
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }

  img,
  video {
    z-index   : 1;
    height    : 70vh;
    object-fit: cover;
    opacity   : 0;
    transition: opacity ease 1s;
    position  : absolute;
    top       : 0;
    left      : 0;
    width     : 100%;
    height    : 100%;
    display   : block;
  }
}

.slide-content {
  @extend .container;
  height    : 100%;
  text-align: center;
  z-index   : 2;
}

.slide-inner {
  height: 100%;
  @extend .row;
  @extend .align-items-center;
  @extend .justify-content-md-center;
}

.iframeWrapper {
  position      : absolute;
  top           : 0;
  left          : 0;
  width         : 100%;
  height        : 100%;
  z-index       : 1;
  pointer-events: none;
  overflow      : hidden;

  iframe {
    width     : 100vw;
    height    : 56.25vw;
    min-height: 100vh;
    min-width : 177.77vh;
    position  : absolute;
    top       : 50%;
    left      : 50%;
    transform : translate(-50%, -50%);
  }
}

.loaded .masthead img,
.loaded .masthead video {
  opacity   : 1;
  transition: opacity ease 1s;
}

h3.underline-title {
  text-transform: uppercase;
  color         : $brand-primary;
  margin        : 1em 0;
  position      : relative;
  padding       : 10px;
  z-index       : 2;
  opacity       : 0;
  transform     : translateY(2em);
  transition    : all ease .5s;
  display       : inline-block;

  &:after {
    content         : '';
    position        : absolute;
    height          : 2px;
    background-color: $brand-primary;
    top             : 90%;
    left            : 50%;
    width           : 0;
    transition      : all ease .5s;
  }

  .loaded & {
    opacity         : 1;
    transform       : translateY(0);
    transition      : all ease .5s;
    transition-delay: 1s;

    &:after {
      width           : 50%;
      left            : 25%;
      transition      : all ease .5s;
      transition-delay: 2s;
    }
  }

}

.single-masthead {
  position  : relative;
  height    : 40vh;
  opacity   : 1;
  overflow  : hidden;
  color     : $white;
  background: $black;
  min-height: 250px;

  img {
    object-fit: cover;
    transition: opacity ease 1s;
    position  : absolute;
    top       : 0;
    left      : 0;
    width     : 100%;
    height    : 100%;
    display   : block;
  }
}
