.careerPageItem{
    padding      : 2em;
    // border       : 1px solid $grey;
    // background   : #ebebeb;
    border-radius: .25rem;
    // margin-bottom: 2em;
  }
  .career-inner{
    h2{
      font-size: 1.35rem;
      margin-bottom: 1rem;
    }
  }

.careers-section{
  padding-top: 0;
  background-color: $light ;
  .careers-block {
    padding: 4rem 0;
  }
  .apply-form{
    padding-top: 2rem; 
  }
}