
// Grid system

// .main {
//   @include make-col-ready();
//   @include media-breakpoint-up(sm) {
//      @include make-col($main-sm-columns);
//
//     .sidebar-primary & {
//       @include make-col($main-sm-columns - $sidebar-sm-columns);
//     }
//   }
// }
//
// .sidebar {
//   @include make-col-ready();
//   @include media-breakpoint-up(sm) {
//      @include make-col($sidebar-sm-columns);
//   }
// }

.mt-1n {
  @include media_breakpoint-up(md) {
    margin-top: -5rem;
    z-index   : 100;
  }
  @include media_breakpoint-up(lg) {
    margin-top: -10rem;
    z-index   : 100;
  }
}

////////////////////////
// Show Off Grid
///////////////////////
.col__inner {
  &--right {
    text-align: center;
    @include media_breakpoint-up(md) {
      padding   : 0 2rem;
      text-align: left;
    }
    @include media_breakpoint-up(lg) {
      padding: 0 9rem 0 4rem;
    }
  }

  &--left {
    text-align: center;
    @include media_breakpoint-up(md) {
      padding   : 0 2rem;
      text-align: left;
    }
    @include media_breakpoint-up(lg) {
      padding: 0 5rem 0 10rem;
    }
  }
}

////////////////////////
// No Gutters
///////////////////////
.no-margin {
  margin-right: 0;
  margin-left : 0;

   > [class*="col-"],
  > .col {
    padding-right: 0;
    padding-left : 0;
  }
}
