// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
@mixin break-sm() {
  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin break-md() {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin break-lg() {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin break-xl() {
  @include media-breakpoint-up(xl) {
    @content;
  }
}

@mixin padding($small:2rem, $medium:$small, $large:$medium) {

  padding-top: $small;
  padding-bottom: $small;


  @include break-md() {
    padding-top: $medium;
    padding-bottom: $medium;
  }

  @include break-lg() {
    padding-top: $large;
    padding-bottom: $large;
  }
}


@keyframes menuDown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
