//////////////////////////
// Top Nav
/////////////////////////

.logo {
  display: block;
  max-width: 70px;
  top: 0;
  position: relative;

  @include media-breakpoint-up(md) {
    max-width: 120px;
    top: 0;
  }
}

.main-nav {
  position: absolute;
  z-index: 1000;
  width: 100%;

  .single-work & {
    background: linear-gradient(rgba($black, .5), rgba($black, 0));
  }

  ul {
    margin-bottom: 0;
  }

  ul>li {
    list-style: none;

    a {
      font-weight: 400;
      color: $white;
      padding: .75em 1.5em;
      position: relative;
      font-size: 1.1em;

      // Hover Effect
      &:after {
        content: '';
        background: #df1e36;
        width: 0;
        height: 2px;
        position: absolute;
        left: 1.5em;
        bottom: .5em;
        transition: width ease .3s;
        z-index: -1;
        word-break: break-all;
      }

      &:focus:after,
      &:hover:after {
        width: calc(100% - 3em);
        transition: width ease .3s;
      }

    }
  }
}

.page-template-work-page .main-nav ul>li a {
  color: $text;

  &.button-cta {
    color: $white;
  }
}

.post-template-default .main-nav ul>li a {
  color: $text;

  &.button-cta {
    color: $white;
  }
}

.page-template-page-careers .main-nav ul>li a {
  color: $text;

  &.button-cta {
    color: $white;
  }
}


.overlayOpen {
  opacity: 1;
  pointer-events: all;
  transition: all ease .5s;
  transition-delay: .3s;
}

.overlayOpen:after {
  z-index: -1;
  content: 'DAVIS';
  position: absolute;
  top: 14%;
  font-size: 62vw;
  width: 106%;
  font-weight: 800;
  line-height: 1em;
  color: rgba(72, 72, 72, 0.11);
}

//////////////////////////
// Menu Icon
/////////////////////////
.menu-icon {
  display: block;
  z-index: 9000;
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 15px;
  height: 44px;
  color: transparent;
  width: 44px;
  transition: all .2s cubic-bezier(0, 0, 0.2, 1);

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.transition,
.transition:after,
.transition:before {
  transition: all .3s cubic-bezier(0.68, -0.5, 0.27, 0.98);
  -webkit-backface-visibility: hidden;
}

/* MENU Icon */

.bar {
  display: inline-block;
  width: 40px;
  height: 6px;
  position: absolute;
  border-radius: 6px;
  margin: 10em;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: $white;
  border-color: $white;
  color: $white;

  .page-template-work-page-php & {
    background-color: $black-1;
    border-color: $black-1;
    color: $black-1;
  }

  .post-template-default & {
    background-color: $black-1;
    border-color: $black-1;
    color: $black-1;
  }

  .page-template-page-careers & {
    background-color: $black-1;
    border-color: $black-1;
    color: $black-1;
  }

  .open & {
    background-color: transparent;
    border-color: $white;
    color: $white;
  }

  &:after,
  &:before {
    display: block;
    content: ' ';
    background-color: transparent;
    position: absolute;
    width: 40px;
    height: 0;
    border-radius: 6px;
  }

  &:before {
    top: 0;
    right: 0;
    border: 3px solid;
    margin-top: .75em;
  }

  &:after {
    bottom: 0;
    right: 0;
    border: 3px solid;
    margin-bottom: .75em;
  }
}

.change {
  background-color: transparent;

  &:after,
  &:before {
    margin: 0;
  }

  &:after {
    transform: rotate(-135deg);
  }

  &:before {
    transform: rotate(135deg);
  }
}

@-webkit-keyframes cd-translate {
  0% {
    -webkit-transform: translateX(0%);
  }

  50% {
    -webkit-transform: translateY(10px) translateX(0%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes cd-translate {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  50% {
    -webkit-transform: translateY(10px) translateX(0%);
    transform: translateY(10px) translateX(0%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

//////////////////////////
// Mobile Menu
/////////////////////////
.overlay-menu {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, visibility 0s .5s;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $black;
  z-index: 8000;

  .container,
  .row {
    height: 100%;

  }

  &.open {
    opacity: 1;
    visibility: visible;
    transition: opacity .5s;
  }

  .nav-mobile {
    ul {
      list-style: none;
      text-align: center;
    }

    a {
      padding: 2rem;
      display: block;
      color: $white;
      position: relative;
      font-size: 1.1em;

      &:focus,
      &:hover {
        background: $brand-primary;
        transition: all 1s ease;
      }
    }

    .open & {
      opacity: 1;
      transform: rotateX(0deg);

      li {
        @extend .animated;
        @extend .fadeInDown;
        animation-delay: 600ms;

        &:nth-child(1) {
          animation-delay: 200ms;
        }

        &:nth-child(2) {
          animation-delay: 300ms;
        }

        &:nth-child(2) {
          animation-delay: 400ms;
        }

        &:nth-child(3) {
          animation-delay: 500ms;
        }

        &:nth-child(4) {
          animation-delay: 500ms;
        }

      }
    }

    .close & {
      opacity: 1;
      transform: translateY(25%) rotateX(-35deg);
    }
  }
}




// MENU
.menu-item-has-children {
  animation: display 300ms;
  transform-origin: center bottom;
  position: relative;

  &:hover {
    .dropdown-menu {
      display: block;
      animation: menuDown 300ms;
      transform-origin: center top;
    }
  }

  .dropdown-menu {
    display: none;
    padding: .25rem;
    position: absolute;
    width: 240px;
    left: 0;
    right: 0;
    flex-direction: row;
    margin: 0;
    background: $black;
    top: 100%;
    height: auto;
    z-index: 1000;

    li {
      border-bottom: 1px solid rgba($white, 0.1);
      padding:0;
      margin:0;
    }

    a {
      display: block;
      width: 100%;
      padding: .35rem;
      margin-top: .35rem;
      &:after{
        display:none;
      }

      &:hover {
        text-decoration: none;
        color: $primary;
        cursor: pointer;
        border:none;
      }
    }
  }
}


#main-menu{ 
  display:flex;
  @extend .align-items-center;
  ul>li{
    @extend .col;
  }
  .cta a{
    @extend .button-cta;
  }
}