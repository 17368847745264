@import url( 'https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900|Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@font-face {
  font-family: 'fontello';
  src        : url('../fonts/fontello.eot');
  src        : url('../fonts/fontello.eot#iefix') format('embedded-opentype'), url('../fonts/fontello.woff2') format('woff2'), url('../fonts/fontello.woff') format('woff'), url('../fonts/fontello.ttf') format('truetype'), url('../fonts/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style : normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?24240395#fontello') format('svg');
  }
}
*/
[class*=" icon-"]:before,
[class^="icon-"]:before {
  font-family            : 'fontello';
  font-style             : normal;
  font-weight            : normal;
  speak                  : none;
  display                : inline-block;
  text-decoration        : inherit;
  width                  : 2em;
  margin-right           : .2em;
  text-align             : center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant           : normal;
  text-transform         : none;

  /* fix buttons height, for twitter bootstrap */
  line-height            : 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left            : .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-icon:before {
  content: '\e813';
}
/* '' */
.icon-group-6:before {
  content: '\e814';
}
/* '' */
.icon-creative:before {
  content: '\e815';
}
/* '' */
.icon-media:before {
  content: '\e816';
}
/* '' */
.icon-strategy:before {
  content: '\e817';
}
/* '' */
.icon-phone-contact:before {
  content: '\e818';
}
/* '' */
.icon-arrowcircle:before {
  content: '\e819';
}
/* '' */
.icon-plane:before {
  content: '\e81a';
}
/* '' */
.icon-twitter:before {
  content: '\e81b';
}
/* '' */
.icon-linked-in:before {
  content: '\e81c';
}
/* '' */
.icon-facebook:before {
  content: '\e81d';
}
/* '' */
.icon-speaker:before {
  content: '\e81e';
}
/* '' */
.icon-locations:before {
  content: '\e81f';
}
/* '' */
.icon-team:before {
  content: '\e820';
}
/* '' */
.icon-notebook:before {
  content: '\e821';
}
/* '' */
.icon-handshake:before {
  content: '\e822';
}
/* '' */
.icon-instagram:before { 
  content: '\f16d';
}