///////////////////////
// Work Shared Styles
///////////////////////

.single-work {
  h2,
  h3 {
    margin-bottom: 1.5rem;
  }
}

.single-work-intro {
  background    : $law;
  background    : linear-gradient(to bottom, lighten($law,10%) 0%,$law 100%);

  &:after {
    background-color: $light;
  }

  .priority-automotive & {
    background: #2e2e2e;
    background: linear-gradient(to bottom, #2e2e2e 0%,#0e0e0e 100%);

    &:after {
      background-color: $light;
    }
  }
  .issue-advocacy & {
    background: $issue;
    background: linear-gradient(to bottom, $issue 0%,#2d3e50 100%);

    &:after {
      background-color: $light;
    }
  }

  .arbys & {
    background: $arbys;
    background: linear-gradient(to bottom, $arbys 0%,darken($arbys,10%) 100%);

    &:after {
      background-color: $white;
    }
  }

  .dunkin-donuts & {
    background: #ea5ca4;
    background: linear-gradient(to bottom, #ea5ca4 0%,#cf2e7e 100%);

    &:after {
      background-color: $white;
    }
  }

  .chkd & {
    background: #1690bf;
    background: -webkit-linear-gradient(top, #1690bf 0%,#1a639a 100%);

    &:after {
      background-color: $white;
    }
  }

  .adsinc & {
    background: #2e2e2e;
    background: linear-gradient(to bottom, #2e2e2e 0%,#0e0e0e 100%);

    &:after {
      background-color: $white;
    }
  }

  .rnr & {
    background: #2e2e2e;
    background: linear-gradient(to bottom, #2e2e2e 0%, #0e0e0e 100%);

    &:after {
      background-color: $white;
    }
  }
  
  .change &{
    background    : $change;
    background    : linear-gradient(to bottom, $change 0%,darken($change,10%) 100%);

    &:after {
      background-color: $light;
    }
  }


  position      : relative;
  padding-bottom: 4em;
  padding-top   : 4em;
  z-index       : 2;
  color         : $white;
  @include media-breakpoint-up(md) {
    padding-top   : 6em;
    padding-bottom: 12em;
  }
  @include media-breakpoint-up(lg) {
    padding-top   : 8em;
    padding-bottom: 16em;
  }

  .client-logo {
    max-width: 200px;
    @include media-breakpoint-up(md) {
      max-width: 300px;
    }
  }

  p {
    margin: 1em auto;
    @include media-breakpoint-up(md) {
      margin: 2em auto;
    }
  }

  h1 {
    text-transform: uppercase;
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }
  @include media-breakpoint-up(md) {
    &:after {
      content          : '';
      position         : absolute;
      width            : 100%;
      bottom           : -4vw;
      left             : 0;
      height           : 8vw;
      background-color : $light;
      -webkit-transform: skewy(4deg);
      transform        : skewy(4deg);
    }
  }
}

///////////////////////
// Work Section
///////////////////////
.section {
  &__work {
    position      : relative;
    padding-top   : 4rem;
    padding-bottom: 4rem;
    @include media_breakpoint-up(md) {
      padding-top   : 8rem;
      padding-bottom: 8rem;
    }

    &.angle {
      &__bottom {
        position      : relative;
        padding-top   : 4rem;
        padding-bottom: 6rem;
        @include media_breakpoint-up(md) {
          padding-top   : 8rem;
          padding-bottom: 12rem;
        }
      }

      &__top {
        position      : relative;
        padding-top   : 6rem;
        padding-bottom: 4rem;
        @include media_breakpoint-up(md) {
          padding-top   : 12rem;
          padding-bottom: 8rem;
        }
      }

    }

    &--pad-top {
      position      : relative;
      padding-top   : 4rem;
      padding-bottom: 0;
      @include media_breakpoint-up(md) {
        padding-top   : 8rem;
        padding-bottom: 0;
      }
    }

    &--pad-bottom {
      position      : relative;
      padding-top   : 2rem;
      padding-bottom: 4rem;
      @include media_breakpoint-up(md) {
        padding-top   : 0;
        padding-bottom: 8rem;
      }
    }

    &--color {
      @extend .section__work;
      background: $law;

      .issue-advocacy & {
        background: $issue;
      }
      .priority-automotive & {
        background: $priority;
      }

      .dunkin-donuts & {
        background: $dunkin;
      }

      .adsinc & {
        background: $ads;
      }

      .chkd & {
        background: $chkd;
      }

      .arbys & {
        background: $arbys;
      }
      
      .rnr & {
        background: $rnr;
      }
      .change & {
        background: $change;
      }


      color     : $white;

      &:after {
        content          : '';
        position         : absolute;
        width            : 100%;
        bottom           : -8vw;
        left             : 0;
        height           : 12vw;
        background-color : $white;
        -webkit-transform: skewy(4deg);
        transform        : skewy(4deg);
      }
    }

    &--gray {
      @extend .section__work;
      background: $gray-lighter;
    }

  }
}

///////////////////////
// Overides
///////////////////////
.gray {
  background    : $gray-lighter;
  padding-top   : 2rem;
  padding-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    padding-top   : 0;
    padding-bottom: 0;
  }

  img {
    @include media-breakpoint-up(md) {
      margin-top: -8rem;
    }
  }
}

.grey {
  background: $light;
}

.pink {
  background: #de4892;
  color     : $white;

  img {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-top: -6rem;
    }
  }

  .backroundCat::after {
    color: rgba($white, .1);
  }
  @include media-breakpoint-up(md) {
    &:before {
      content          : '';
      position         : absolute;
      width            : 100%;
      bottom           : -4vw;
      left             : 0;
      height           : 20vw;
      top              : -4vw;
      background-color : $light;
      -webkit-transform: skewy(-4deg);
      transform        : skewy(-4deg);
    }
  }
}
///////////////////////
// Work Quote
///////////////////////
.quote-section {
  position: relative;
  @include media-breakpoint-down(md) {
    padding-top   : 2rem;
    padding-bottom: 2rem;
  }

  &:after {
    content    : '\201C';
    position   : absolute;
    top        : -12rem;
    left       : -6rem;
    font-size  : 60rem;
    opacity    : .05;
    line-height: 1;
    z-index    : -1;
  }

  .quote {
    /* CSS declarations for `.block__element` */
    font-style : italic;
    line-height: 1.75;
    font-size  : 1rem;
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      font-size: 1.15rem;
    }
  }

  .author {
    font-weight   : 600;
    text-transform: uppercase;
    font-size     : 1.25rem;
    display       : block;
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
  }

  .byline {
    font-size  : 1rem;
    color      : darken($grey,40%);
    font-weight: 300;

  }

  .seperator__lg--gray {
    margin: 2rem 0 1rem;
  }

}

///////////////////////
// Work Second
///////////////////////

.single-work-second {
  position: relative;
  margin  : -3rem auto 0;
  position: relative;

  .video-wrapper {
    z-index : 1000;
    position: relative;
  }
  @include media-breakpoint-up(md) {
    padding: 0 0 6rem;
    margin : -6rem auto 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 0 16rem;
    margin : -10rem auto 2rem;
  }

  .priority-automotive & {
    @extend %angle-after;
  }

  .copy {
    text-align: center;
    margin    : 2em auto;
    @include media-breakpoint-up(lg) {
      margin: 5em auto 2em;
    }

    .seperator__lg--gray {
      margin: 2rem auto 1rem;
    }
  }
}

%angle-after {
  &:before {
    content   : '';
    width     : 100%;
    position  : absolute;
    background: $light;
    height    : 100%;
    z-index   : -1;
  }

  &:after {
    background       : $white;
    content          : '';
    position         : absolute;
    width            : 100%;
    bottom           : -8vw;
    left             : 0;
    height           : 16vw;
    -webkit-transform: skewy(-8deg);
    transform        : skewy(-8deg);
    z-index          : -1;
  }
}

///////////////////////
// Logo
///////////////////////
.logo-cta {
  display       : block;
  padding       : 3rem 6rem;
  text-transform: uppercase;
  color         : $brand-primary;
  text-align    : center;
  border        : 2px solid $brand-primary;
  position      : relative;

  &:after {
    z-index   : -1;
    content   : '';
    position  : absolute;
    left      : 0;
    top       : 0;
    width     : 0;
    opacity   : 0;
    height    : 100%;
    display   : block;
    background: $brand-primary;
    transition: all .5s ease;
  }

  &:focus,
  &:hover {
    color     : $white;
    transition: all .5s ease;

    &:after {
      width     : 100%;
      opacity   : 1;
      transition: all .5s ease;
    }
  }
}

///////////////////////
// Computer
///////////////////////

.computer-img {
  margin-bottom: -10rem;
  z-index      : 100;
  position     : relative;
  @include media-breakpoint-up(md) {
    margin-bottom: -18rem;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: -18rem;
  }
}

///////////////////////
// Law Persons
///////////////////////
.harris {
  @include media-breakpoint-down(sm) {
    border-radius: 50%;
    margin       : 0 4rem;
    border       : 1rem solid $light;
    max-width    : 70%;
  }
}

.circ-img {
  @include media-breakpoint-down(sm) {
    border-radius: 50%;
    margin       : 2rem auto;
    border       : 1rem solid $light;
  }
}

.vaughn {
  z-index: 3;
  @include media-breakpoint-down(sm) {
    max-width  : 70%;
    left       : 3rem;
    position   : relative;
    margin-left: 4rem;
  }
  @include media-breakpoint-down(md) {
    background   : $white;
    padding-top  : 2rem;
    border-radius: 50%;
    border       : 1rem solid $light;
    margin-left  : 2rem;
  }
}

.computerOverlay {
  width   : 100%;
  position: relative;
  @include media-breakpoint-down(md) {
    display: none;
  }

  .circle {
    position: absolute;
    top     : 0;
    left    : -20%;

    .iframe {
      position       : absolute;
      top            : 25%;
      left           : 20%;
      width          : 60%;
      height         : 50%;
      display        : flex;
      justify-content: center;
      align-items    : center;
    }
  }

  img {
    width: 100%;
  }
}
///////////////////////
// Issue Advocacy
///////////////////////
.research {
  max-width: 500px;
  padding: 2em;
  @include media-breakpoint-down(sm) {
    max-width    : 70%;
  }
}



///////////////////////
// Number Callouts
///////////////////////

.number {
  text-align: center;

  span {
    font-size  : 3em;
    font-weight: 900;
    position   : relative;
    color      : $white;
    opacity    : .8;
    @include media-breakpoint-up(lg) {
      font-size: 7em;
    }

    &:after {
      content           : '';
      position          : absolute;
      height            : 2px;
      background-color  : $white;
      width             : 100%;
      margin            : auto;
      bottom            : 0;
      z-index           : 3;
      -webkit-transition: all ease .5s;
      transition        : all ease .5s;
      right             : 0;
      left              : 0;
      margin            : auto;

    }
  }

  h2 {
    padding    : 0 1em 1em;
    color      : $white;
    position   : relative;
    z-index    : 1;
    font-size  : 1rem;
    line-height: 1.5;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }

  }

}

///////////////////////
// ADS
///////////////////////

.ads-print {
  background: $light;
  margin-top: 10rem;
  @include media-breakpoint-up(md) {
    margin-top: 15rem;
  }

  img {
    margin-top: -10rem;
    @include media-breakpoint-up(md) {
      margin-top: -15rem;
    }
  }
}

.ads-billboard {
  color              : $white;
  background-position: left top;
  text-shadow        : 0 0 5px rgba($black,.8);
  background-size    : contain;
  background-repeat  : no-repeat;
  background-color   : #181818;
  padding-top        : 15rem;
  @include media-breakpoint-up(md) {
    padding-top   : 25rem;
    padding-bottom: 5rem;
  }
  @include media-breakpoint-up(lg) {
    background-size: cover;
    padding-top    : 40rem;
    padding-bottom : 10rem;
  }

  &:before {
    content          : '';
    position         : absolute;
    width            : 100%;
    bottom           : -4vw;
    left             : 0;
    height           : 10vw;
    top              : -4vw;
    background-color : $light;
    -webkit-transform: skewy(4deg);
    transform        : skewy(4deg);

  }

}

///////////////////////
// CHKD
///////////////////////
.chkd-pink {
  background   : #c0166f;
  position     : relative;
  color        : $white;
  margin-bottom: 10rem;
  @include media-breakpoint-down(md) {
    margin-top   : 8rem;
    margin-bottom: 0;
  }

  .backroundCat::after {
    color: darken( #c0166f,5%);
  }

  img {
    margin-top: -6rem;
    @include media-breakpoint-up(md) {
      margin-top: -10rem;
    }
  }

  &:after {
    content          : '';
    position         : absolute;
    width            : 100%;
    bottom           : -4vw;
    left             : 0;
    height           : 8vw;
    background-color : #c0166f;
    -webkit-transform: skewy(4deg);
    transform        : skewy(4deg);
  }

  &:before {
    content          : '';
    position         : absolute;
    width            : 100%;
    top              : -4vw;
    left             : 0;
    height           : 8vw;
    background-color : #c0166f;
    -webkit-transform: skewy(4deg);
    transform        : skewy(4deg);
  }
}

.chkd-green {
  background    : #6ea224;
  color         : $white;
  padding-bottom: 0;

  img {
    @include media-breakpoint-up(md) {
      margin-top: -15rem;
    }
  }

  .backroundCat::after {
    color: darken( #6ea224,5%);
  }
}

///////////////////////
// Arbys
///////////////////////
.primary-image {
  z-index : 100;
  position: relative;
  @include media-breakpoint-up(md) {
    margin-top: -4rem;
  }
}

.arbys .number {
  color: $arbys;

  h2 {
    color: $text;
  }

  span {
    color  : $arbys;
    opacity: 1;
  }
}

.trophy img {
  max-height   : 60px;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    max-height: 130px;
  }
}

.arbys .breakoutImage {
  padding-top   : 15px;
  padding-bottom: 15px;
}



///////////////////////
// RNR
///////////////////////
.primary-image {
  z-index: 100;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-top: -4rem;
  }
}

.rnr .number {
  color: $rnr;

  h2 {
    color: $text;
  }

  span {
    color: $rnr;
    opacity: 1;
  }
}

.trophy img {
  max-height: 60px;
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    max-height: 130px;
  }
}

.rnr .breakoutImage {
  padding-top: 15px;
  padding-bottom: 15px;
}


.change .number {
  color: $change;

  h2 {
    color: $text;
  }

  span {
    color: $change;
    opacity: 1;
  }
}
