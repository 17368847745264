@include media-breakpoint-up(xs) {}
@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-down(xs) {}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(lg) {}

img {
  max-width: 100%;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline        : none;
  text-decoration: none;
}

figure {
  margin: 0;
}

body {
  &.open {
    overflow: hidden;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left : 0;

   > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left : 0;
  }
}

.content-area,
.overflow {
  overflow: hidden;
}

.h1 {
  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }
}
//////////////////////////////////
// SECTIONS - Spacing, Type
//////////////////////////////////
.section {
  &.center {
    text-align: center;
  }

  &.no-pad {
    padding: 0;
  }
  &.mini-pad{
  padding-top   : 4em;
  padding-bottom: 4em;
  }
  padding-top   : 4em;
  padding-bottom: 4em;
  @include media-breakpoint-up(md) {
    padding-top   : 8em;
    padding-bottom: 8em;
  }

  // SECTION SUB HEADER
  h3 {
    text-transform: uppercase;
    color         : $dark-grey;
    margin        : 0 0 1rem;
    position      : relative;
    padding       : 1rem 0;
    display       : inline-block;

  }

  h3:after {
    content : '';
    position: absolute;
    border  : $dark-grey 1px solid;
    width   : 50%;
    left    : 25%;
    top     : 90%;
  }

  &.left-text h3:after,
  &.right-text h3:after {
    left: 0;
  }
}

.bottom-zero {
  padding-bottom: 0 !important;
  margin-bottom : 0 !important;
}

.z-top{
  z-index: 5;
}