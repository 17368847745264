.button-cta {
  background-color: $accent-color;
  border-radius   : 2em;
  color           : $white;
  padding         : .75em 1.5em;

  transition      : all ease .5s;
  border          : none;

  &:hover {
    color           : $white;
    background-color: darken($accent-color,10%);
    box-shadow      : 0 1px 7px 0 rgba(0, 0, 0, 0.5);
  }
}

////////////////////
// Callout Section
///////////////////
.section-callout {
  background-color: transparent;
  position: relative;
  transition: all .5s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);

  @include media-breakpoint-up(md) {
    // padding: 2rem 0;
  }

  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: $brand-primary;
    transition: all .5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
  }

  &:hover {
    &:after {
      width: 100%;
    }

    .cta-main,
    .cta-sub {
      color: $white;
      transition: all .5s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .cta-main {
    transition: all .5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
    padding: 0;
    font-weight: 600;
    font-size: 2em;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @include media-breakpoint-up(md) {
      font-size: 3em;
    }
  }
}
