/* Client Section - Homepage */
@include media-breakpoint-up(xs) {}
@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-down(xs) {}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(lg) {}

//////////////////////////////////
// Services Section
//////////////////////////////////

.home-services {
  a {
    display: block;

    &:hover {
      color     : $brand-primary;
      background: #fafafa;
    }
  }
}

.services-section {
  padding-top: 2rem;
}

.service-itemInner {
  transition: all ease .5s;
  padding   : 20px;

  h3 {
    color: $dark-grey;
  }
}

.service-item {
  i {
    margin   : 0 auto;
    display  : block;
    font-size: 3em;
  }

  h3 {
    // color: $black;
    transition    : color ease .5s;
    position      : relative;
    display       : inline-block;
    padding-bottom: .35em;

    &:after {
      content   : '';
      background: $brand-primary;
      border    : none;
      width     : 0;
      height    : 2px;
      position  : absolute;
      left      : .5em;
      bottom    : 0;
      transition: width ease .3s;
    }
  }

  p {
    opacity   : 0;
    transform : translateY(20px);
    transition: all ease .5s;
    color     : $black;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.serviceItem:focus h3:after,
.serviceItem:hover h3:after {
  width     : calc(100% - 1em);
  transition: width ease .3s;
}

.serviceItem:hover .serviceItemInner {
  -webkit-transition: all ease .9s;
  transition        : all ease .9s;
}

.serviceItem:hover h3 {
  color             : $black;
  -webkit-transition: color ease .5s;
  transition        : color ease .5s;
}

.service-item:hover p {
  opacity   : 1;
  transform : translateY(0px);
  transition: all ease .5s;
}

//////////////////////////////////
// Numbers Section
//////////////////////////////////
.numbers-section {
  background: $accent-color;
}

.numbers {
  opacity   : 1;
  transform : translateY(0em);
  transition: all ease 1s;

  i {
    font-size: 3em;
    color    : rgba($white,.5);
  }

  p {
    color: rgba($white,.5);
  }

  .counter {
    color        : $white;
    font-size    : 3em;
    padding-top  : .5em;
    margin-bottom: .2em;
  }

  > div:nth-child(1n+2)::after {
    content    : '';
    position   : absolute;
    border-left: 1px solid rgba($white,.2);
    height     : 50%;
    top        : 25%;
    left       : 0;
  }
}

/////////////////////
// Work Section
////////////////////
.section-work {
  background: #f4f4f4;

  .img-container {
    height    : 300px;
    padding   : 2rem 0 0;
    text-align: center;
    position  : relative;

    img {
      z-index   : 4;
      object-fit: cover;
      opacity   : 1;
      transition: opacity ease 1s;
      position  : absolute;
      top       : 0;
      left      : 0;
      width     : 100%;
      height    : 100%;
      display   : block;
    }
  }

}

.work-item {
  height    : 100%;
  text-align: center;

  &.priority-automotive {
    background: #2e2e2e;
    background: linear-gradient(to bottom, #2e2e2e 0%,#0e0e0e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#2e2e2e', endColorstr='#0e0e0e',GradientType=0 );
  }
  &.issue-advocacy {
    background: $issue;
    background: linear-gradient(to bottom, $issue 0%,#2d3e50 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '$issue', endColorstr='#2d3e50',GradientType=0 );
  }

  &.dunkin-donuts {
    background: #ea5ca4;
    background: linear-gradient(to bottom, #ea5ca4 0%,#cf2e7e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#ea5ca4', endColorstr='#cf2e7e',GradientType=0 );
  }

  &.chkd {
    background: #1690bf;
    background: linear-gradient(to bottom, #1690bf 0%,#1a639a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#1690bf', endColorstr='#1a639a',GradientType=0 );
  }

  &.ads {
    background: #97b106;
    background: linear-gradient(to bottom, #97b106 0%,#647508 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#97b106', endColorstr='#647508',GradientType=0 );
  }

  &.law-firm-marketing {
    background: #202a45;
    background: linear-gradient(to bottom, lighten(#202a45,10%) 0%,#202a45 100%);
  }

  &.arbys {
    background: #d71a21;
    background: linear-gradient(to bottom, #d71a21 0%,darken(#d71a21,10%) 100%);
  }

  &.rnr-tire-express {
    background: #2e2e2e;
    background: linear-gradient(to bottom, #2e2e2e 0%, #0e0e0e 100%); 
  }

  &.priorities-for-change{
    background    : $change;
    background    : linear-gradient(to bottom, $change 0%,darken($change,10%) 100%);

  }
}

.work-title {
  color         : $white;
  position      : relative;
  margin-top    : 1rem;
  padding-top   : 1rem;
  margin-bottom : 1rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-size     : 16px;
  letter-spacing: 2px;

  &:before {
    content   : '';
    width     : 100px;
    border-top: 2px solid;
    position  : absolute;
    top       : 0;
    left      : 0;
    right     : 0;
    margin    : auto;
  }
}

.work-item-wrapper {
  box-shadow   : 0 0 10px rgba($black, .2);
  margin-bottom: 2rem;
}

.work-img-wrapper {
  display: block;
  padding: 2rem 0;
  @include media-breakpoint-up(md) {
    height: 10rem;
  }
}

.work-img {
  max-width : 50%;
  display   : block;
  margin    : 0 auto;
  max-height: 100%;
}

.work-feat {
  @extend .fadeInLeft;
  animation-delay   : 1s;
  animation-duration: 1s;
}

.work-link {
  color         : $white;
  padding       : 1rem 5rem;
  display       : inline-block;
  border        : 2px solid $white;
  position      : relative;
  z-index       : 1;
  transition    : all 1s ease;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:after {
    background: $white;
    width     : 0;
    position  : absolute;
    content   : '';
    height    : 100%;
    display   : block;
    top       : 0;
    left      : 0;
    z-index   : -1;
    opacity   : 0;
    transition: all 1s ease;
  }

  &:hover {
    color     : $black;
    transition: all .5s ease;

    &:after {
      transition: all .5s ease;
      width     : 100%;
      opacity   : 1;
    }
  }
}

//////////////////////////////////
// Client Section
//////////////////////////////////
.clientItem img {
  // filter: grayscale(100%);
  transition: all ease .5s;
}

.clientItem:hover img {
  filter:grayscale(0%);
  transition: all ease .5s;
}
